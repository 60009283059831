.map {
    max-height: calc(100vh - 300px);
    min-width: 320px;
}

.home {
    margin-top: 25px;
    max-height: calc(100vh - 100px);
}

.intro {
    margin-top: 25px;
    max-height: calc(100vh - 250px);
}

.carousel {
    margin-top: 25px;
}

.page-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75px;
    width: 100%;
}

a.nostyle:link {
    text-decoration: inherit;
    color: inherit;
}